<template>
  <nav class="nav">
    <div class="inner">
      <div class="btn_wrap">
        <div class="btn_box">
          <img src="@/assets/static/images/i_speakerphone.svg" alt="icon" />
          <span class="txt"
            >2021년 7월 통장 이력부터 확인하실 수 있습니다.</span
          >
        </div>
      </div>
    </div>
  </nav>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="list_page2">
          <div class="form_wrap2" style="background-color: #fff;">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <div class="inline">
                      <label style="flex:1.4;">
                        <select name="year" class="select" v-model="year">
                          <option
                            v-for="(item, index) in yearList"
                            :key="index"
                            :value="item"
                            >{{ item }}년</option
                          >
                        </select>
                      </label>
                      <label>
                        <select name="month" class="select" v-model="month">
                          <option
                            v-for="(item, index) in monthList"
                            :key="index"
                            :value="item"
                            >{{ item }}월</option
                          >
                        </select>
                      </label>
                      <button class="btn orange" @click="fnSearch">
                        조회
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <p><br /></p>
          </div>
        </div>
        <div class="page_con" :class="{ page_bg: $Util.isEmpty(items) }">
          <bank-li-list-index :items="items" v-if="!$Util.isEmpty(items)" />
          <empty-index v-else :content="'통장 이력이 없습니다.'" />
          <div v-observe-visibility="visibilityChanged" :key="clearIndex" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from 'vue'
import BankLiListIndex from '@/components/views/bank/li-list'
import EmptyIndex from '@/components/views/empty'

export default {
  name: 'bank-book-index',
  components: { EmptyIndex, BankLiListIndex },
  description: '통장이력 페이지',
  setup() {
    const { proxy } = getCurrentInstance()

    const date = new Date()

    const state = reactive({
      yearList: [],
      monthList: [],
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      params: {
        page: 1,
        limit: 10,
        year: date.getFullYear(),
        month: date.getMonth() + 1
      },
      items: [],
      pageInfo: [],
      clearIndex: 0
    })

    const fnYear = () => {
      for (let i = 2021; i <= 2051; i++) {
        state.yearList.push(i)
      }
    }

    const fnMonth = () => {
      for (let i = 1; i <= 12; i++) {
        state.monthList.push(i)
      }
    }

    const fnListBox = async () => {
      const res = await proxy.$UserSvc.fetchUserBankBook(state.params)
      if (res.code === 1) {
        state.items = state.items.concat(res.entity.list)
        state.pageInfo = res.entity.pageInfo
        state.params.page++
      }
    }

    const fnSearch = async () => {
      state.params.year = state.year
      state.params.month = state.month
      const res = await proxy.$UserSvc.fetchUserBankBook(state.params)
      if (res.code === 1) {
        fnClear()
      }
    }

    const fnClear = () => {
      state.params.page = 1
      state.pageInfo = {}
      state.items = []
      state.clearIndex++
    }

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        if (state.pageInfo.totalPage < state.params.page) return false
        await fnListBox()
      }
    }

    fnYear()
    fnMonth()
    return {
      ...toRefs(state),
      fnYear,
      fnMonth,
      fnListBox,
      visibilityChanged,
      fnSearch
    }
  }
}
</script>

<style scoped></style>
