<template>
  <div class="box_shadow m_8" v-for="(item, index) in items" :key="index">
    <div class="box box_one">
      <div class="box_list_wrap">
        <div class="box_list">
          <div class="list_ttl">
            <div class="left">
              <p>{{ item.contents }}</p>
              <div class="price_box">
                <span class="price_txt">{{
                  $dayjs(item.tranDate).format('YYYY.MM.DD HH:mm:ss')
                }}</span>
              </div>
            </div>
            <div class="middle_box">
              <div class="middle">
                <p class="price refresh">
                  <span class="number">{{
                    item.deposit > 0
                      ? $Util.formatNumber(item.deposit)
                      : $Util.formatNumber(item.withdraw)
                  }}</span
                  >원
                </p>
                <div class="price_box">
                  <span class="price_txt deposit" v-if="item.deposit > 0"
                    >입금</span
                  >
                  <span class="price_txt withdrawal" v-else>출금</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line_grey none"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bank-li-list-index',
  description: '통장이력 리스트',
  props: {
    items: {
      type: [Array],
      default: () => {}
    }
  }
}
</script>

<style scoped></style>
