<template>
  <div class="bill_speech_box">
    <img src="@/assets/static/images/i_speech.svg" alt="icon" />
    <span>{{ content }}</span>
  </div>
</template>

<script>
export default {
  name: 'empty-index',
  description: '리스트 없을때',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
